
import Alert from '@/components/Alert.vue'
import { EventBus } from '@/services/event-bus'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VIcon from '@/components/VIcon.vue'
import { NotificationPayloadType } from '@/services/notification'

@Component({
  name: 'NotificationsList',
  components: { Alert, VIcon },
})
export default class NotificationsList extends Vue {
  @Prop({ default: 5000 }) private timeout!: string

  notifications: NotificationPayloadType[] = []

  removeNotification (notification: NotificationPayloadType): void {
    this.notifications = this.notifications.filter((n) => n !== notification)
  }

  clear (): void {
    this.notifications = []
  }

  mounted (): void {
    EventBus.on('notification:create', ({ notification, params = {} }) => {
      console.log('Pushing from LisT:');
      console.log(notification);
      this.notifications.push(notification as NotificationPayloadType)

      setTimeout(() => {
        this.removeNotification(notification)
      }, params.timeout || this.timeout)
    })

    EventBus.on('notification:clear', () => {
      this.clear()
    })
  }
}
