var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notifications z-100" },
    _vm._l(_vm.notifications, function (notification, index) {
      return _c("Alert", {
        key: index,
        attrs: {
          type: notification.type,
          icon: notification.icon,
          message: notification.message,
          details: notification.details,
          "show-close": true,
        },
        on: {
          close: function ($event) {
            return _vm.removeNotification(notification)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }