
import { EventBus } from '@/services/event-bus'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { confirmPayment, goNextStep, goPreviousStep } from '@/api/checkout'
import { CheckoutState } from '@/constants/checkout-state'
import { WithRoute } from '@/types/integrations'
import {
  CheckoutMetaType,
  CheckoutResponseType,
  CustomerDataType,
  PaymentMethodResponseType,
} from '@/types/checkout'
import { Actions, Mutations } from '@/store/modules/checkout'
import { Getter } from '@/decorators/Getter'
import { Action } from '@/decorators/Action'
import { Mutation } from '@/decorators/Mutation'
import ActionBar from '@/components/ActionBar.vue'
import Loadable from '@/components/Loadable.vue'
import MerchantLogo from '@/components/MerchantLogo.vue'
import StepLanding from '@/views/PaymentPage/StepLanding.vue'
import StepsLine from '@/components/StepsLine.vue'
import StepCustomerData from '@/views/PaymentPage/StepCustomerData.vue'
import StepPaymentMethod from '@/views/PaymentPage/StepPaymentMethod.vue'
import { ActionType, MutationType } from '@/types/general'
import { AxiosError } from 'axios'
import StepConfirmation from '@/views/PaymentPage/StepConfirmation.vue'
import { notification, NotificationPayloadType } from '@/services/notification'

@Component({
  name: 'PaymentPage',
  components: {
    StepConfirmation,
    StepPaymentMethod,
    StepCustomerData,
    StepsLine,
    Loadable,
    StepLanding,
    MerchantLogo,
    ActionBar,
  },
})
class PaymentPage extends Vue implements WithRoute {
  @Prop() private customerData!: CustomerDataType;
  @Getter('checkout/meta') meta!: CheckoutMetaType | null
  @Getter('checkout/data') checkoutData!: CustomerDataType | null
  @Getter('checkout/step') state!: CheckoutState

  @Action(`checkout/${Actions.GET_STEP}`) loadCheckoutData!: ActionType<string, Promise<CheckoutResponseType>>

  @Mutation(`checkout/${Mutations.SET_CHECKOUT_DATA}`) setState!: MutationType<CheckoutResponseType, void>
  @Mutation(`checkout/${Mutations.CLEANUP_INVALID}`) cleanupInvalids!: MutationType<void, void>

  isLoading = true
  editData: CustomerDataType | PaymentMethodResponseType | null = null
  customer: CustomerDataType | null = null
  is404 = false
  backgroundColor = '#fff'
  backgroundColor2 = ''
  textColor = '#cba710'
  customFont = ''
  customMenuFont = ''
  customLogoUrl = ''
  customLogoPositionX = '0%'
  customLogoPositionY = '0%'
  customButtonBackgroundColor = '#e4003e'
  customButtonTextColor = '#e4e8ee'
  customFaviconUrl = '/ratepay_favicon.png'
  moto = false
  motoGlobal = false
  edit_customer = false;

  onUpdate (newData:boolean) {
    console.log('On Update Event')
    console.log('motoGLoabal is:' + this.motoGlobal)
    console.log('this.moto is set to ' + newData)
    this.moto = newData && this.motoGlobal
  }

  mounted (): void {
    console.log('clearing storage')
    localStorage.clear()
    console.log('PaymentPage mounted')
    console.log(this.$route.query.foo)
    // console.log(this.foo)

    this.isLoading = true
    console.log('isloading set to true')
    console.log('this.token is' + this.token)
    // console.log('checkoutData')
    var checkoutDataString = localStorage.getItem('checkoutData') ?? '{}'
    this.customer = JSON.parse(checkoutDataString) ?? null
    this.loadCheckoutData(this.token)
      .then((checkoutResponse: CheckoutResponseType): void => {
        console.log('loading checkout Data')
        const data = checkoutResponse.data as CustomerDataType
        var lang = data.customer?.language || 'LOCALE_EN'
        var hasShipping = 'false'
        if (data.shipping_address?.city) {
          hasShipping = 'true'
        } else {
          hasShipping = 'false'
        }
        localStorage.setItem('hasShipping', hasShipping)
        localStorage.setItem('billing', JSON.stringify(data.billing_address))
        console.log('hasShipping is initial:' + hasShipping)
        lang = lang.toLowerCase()
        localStorage.setItem('lang', lang)
        if (data !== null) {
          this.$set(this, 'editData', data)
        }
        // console.log('EditData:')
        // console.log(JSON.stringify(this.editData))
        // console.log('customer:')
        // console.log(JSON.stringify(this.customer))
        if (this.state === CheckoutState.LANDING_PAGE) {
          this.$set(this, 'customer', data)
          console.log('customer:')
          console.log(JSON.stringify(this.customer))
          localStorage.setItem('checkoutData', JSON.stringify(data))
          console.log('snippetId is: ' + data.device.snippetId)
        }
        this.motoGlobal = data.moto
        console.log('data.edit_customer set to' + data.edit_customer)
        this.edit_customer = !data.edit_customer
        console.log('edit_customer set to' + this.edit_customer)

        localStorage.setItem('motoGlobal', this.motoGlobal.toString())
        console.log('setting motoGLobal to data.moto')
        console.log('data.moto is ' + data.moto)

        /*
        console.log('BGColor:' + data.custom_background_color)
        console.log('BGColor2:' + data.custom_background_color2)
        console.log('TextColor:' + data.custom_text_color)
        console.log('Font:' + data.custom_font)
        console.log('Font:' + data.custom_menu_font)
        console.log('Logo Url:' + data.custom_logo_url)
        console.log('Logo X:' + data.custom_logo_position_x)
        console.log('Logo y:' + data.custom_logo_position_y)
        console.log('Button BGColor:' + data.custom_button_background_color)
        console.log('Button TextColor:' + data.custom_button_text_color)
        console.log('Default Payment' + data.default_payment)
        console.log('Moto' + data.moto)
         */
        this.backgroundColor = data.custom_background_color
        this.backgroundColor2 = data.custom_background_color2
        this.textColor = data.custom_text_color
        this.customFont = data.custom_font
        this.customMenuFont = data.custom_menu_font
        this.customLogoUrl = data.custom_logo_url
        this.customLogoPositionX = data.custom_logo_position_x ?? '0%'
        this.customLogoPositionY = data.custom_logo_position_y ?? '0%'
        this.customButtonBackgroundColor = data.custom_button_background_color
        this.customButtonTextColor = data.custom_button_text_color
        this.customFaviconUrl = data.custom_favicon_url
        console.log(this.customLogoPositionX)
        console.log(this.customLogoPositionY);

        (window as any).di = {
          t: data.device.token,
          v: data.device.snippetId,
          l: data.device.location,
        }
        if (!this.motoGlobal) {
          console.log('moto is not true')
          const externalScript = document.createElement('script')
          externalScript.setAttribute('src', '//d.ratepay.com/' + data.device.snippetId + '/di.js')
          document.head.appendChild(externalScript)
        } else {
          console.log('motoGlobal is true')
        }
        console.log('checkoutData After Load')
        console.log(this.checkoutData)
        console.log('CustomFavIcon is: ' + this.customFaviconUrl)
        if (data.custom_favicon_url !== '' && data.custom_favicon_url !== null) {
          this.changeFavicon(this.customFaviconUrl)
        } else {
          this.changeFavicon('/ratepay_favicon.png')
        }
      })
      .catch((error: AxiosError): void => {
        if (error?.response?.status === 404) {
          this.is404 = true

          return
        }
        console.log('PP.vue eoor notify')
        notification.error(error as NotificationPayloadType)
      })
      .finally((): void => {
        this.isLoading = false
      })
  }

  get steps (): CheckoutState[] {
    return this.meta ? this.meta.states : []
  }

  get token (): string {
    return this.$route.params.token
  }

  get foo (): string | (string | null)[] {
    return this.$route.query.foo
  }

  get currentStep (): number {
    return this.meta ? this.meta.states.indexOf(this.state) : 1
  }

  goBack (): void {
    this.isLoading = true
    this.go(goPreviousStep)
  }

  goNext (): void {
    this.isLoading = true

    if (this.state === CheckoutState.CHECKOUT_CONCLUSION) {
      confirmPayment(this.token)
        .then((response) => {
          console.log('Emitter')
          console.log(response)
          EventBus.emit('payment-confirmed')

          return response
        })
        .then(({ data }) => {
          document.location.href = data
        })
        .catch(notification.error)
        .finally((): void => {
          this.isLoading = false
        })

      return
    }

    this.go(goNextStep)
  }

  go (callback: CallableFunction): Promise<void> {
    return callback(this.token, this.preparedRequestData)
      .then(({ data }: { data: CheckoutResponseType }) => {
        console.log('GO')
        this.cleanupInvalids()
        this.setState(data)
        this.$set(this, 'editData', { ...data.data })
        //  if (this.state === CheckoutState.LANDING_PAGE) {
        //   this.$set(this, 'customer', data)
        // }
        // console.log('callback  setting customerData' + JSON.stringify(data.data))
        // console.log('customerData after Callback:' + JSON.stringify(this.customer))
        // this.$set(this, 'customerData', { ...data.data })
      })
      .catch(notification.error)
      .finally((): void => {
        this.isLoading = false
      })
  }

  get actionbarConfig (): Record<CheckoutState, any> {
    return {
      [CheckoutState.LANDING_PAGE]: {
        back: false,
        next: this.$t('next'),
      },
      [CheckoutState.CUSTOMER_DETAILS]: {
        back: this.$t('back'),
        next: this.$t('next'),
      },
      [CheckoutState.PAYMENT_METHODS]: {
        back: this.$t('back'),
        next: this.$t('next'),
      },
      [CheckoutState.CHECKOUT_CONCLUSION]: {
        back: this.$t('back'),
        next: this.$t('action_pay'),
        showAgreement: true,
      },
    }
  }

  get preparedRequestData (): CustomerDataType | PaymentMethodResponseType | null {
    return this.editData
  }

  data () :any {
    return {
      backgroundColor: this.backgroundColor,
      backgroundColor2: this.backgroundColor2,
      textColor: this.textColor,
      customFont: this.customFont,
      customMenuFont: this.customMenuFont,
      customLogoPositionX: this.customLogoPositionX,
      customLogoPositionY: this.customLogoPositionY,
      customButtonBackgroundColor: this.customButtonBackgroundColor,
      customButtonTextColor: this.customButtonTextColor,
      moto: this.moto,
      edit_customer: this.edit_customer,
    }
  }

  getImgUrl () :null|string {
    return this.customLogoUrl
  }

  changeFavicon (src: string) :void {
    let link = document.createElement('link')
    let oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'shortcut icon'
    link.href = src
    if (oldLink) {
      document.head.removeChild(oldLink)
    }
    document.head.appendChild(link)
  }
}

const __default__ = PaymentPage
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "122dcaeb-customLogoPositionX": (_vm.customLogoPositionX),
  "122dcaeb-customLogoPositionY": (_vm.customLogoPositionY),
  "122dcaeb-backgroundColor": (_vm.backgroundColor),
  "122dcaeb-textColor": (_vm.textColor),
  "122dcaeb-customFont": (_vm.customFont)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__