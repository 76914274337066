var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "payment-method-prepayment" }, [
    _c("p", {
      domProps: {
        innerHTML: _vm._s(
          _vm.$t("prepayment_reserved", { days: _vm.days, amount: _vm.amount })
        ),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }