
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AddressType, CustomerDataType, FullCheckoutDataType } from '@/types/checkout'
import VInput from '@/components/VInput.vue'
import VCheckbox from '@/components/VCheckbox.vue'
import VSelect from '@/components/VSelect.vue'
import FormGroup from '@/components/FormGroup.vue'
import AddressForm from '@/views/PaymentPage/AddressForm.vue'
import CustomerForm from '@/views/PaymentPage/CustomerForm.vue'
import { PaymentMethod } from '@/constants/payment-method'

@Component({
  name: 'StepCustomerData',
  components: {
    CustomerForm,
    AddressForm,
    FormGroup,
    VSelect,
    VCheckbox,
    VInput,
  },
})
export default class StepCustomerData extends Vue {
  @Prop() private value!: CustomerDataType;
  @Prop() private checkoutData!: FullCheckoutDataType;
  @Prop() public edit_customer!: boolean;

  data: CustomerDataType = {
    ...this.value,
  }

  get defaultPayment (): PaymentMethod|null|undefined {
    return this.checkoutData.default_payment as PaymentMethod
  }

  hasShipping = !!this.value?.shipping_address

  mounted (): void {
    console.log('mounted StepCustomerData')
    console.log('StepCustomerData edit_customer:' + this.edit_customer)
    if (this.hasShipping) {
      // console.log('hasshipping  true')
      // console.log('this.data.shipping_address')
      // console.log(this.data.shipping_address)
      localStorage.setItem('hasShipping', 'true')
      localStorage.setItem('shipping', JSON.stringify(this.data.shipping_address))
      localStorage.setItem('billing', JSON.stringify(this.data.billing_address))
    }
  }

  getEmptyAddress (): AddressType {
    localStorage.setItem('hasShipping', 'false')

    return {
      first_name: '',
      last_name: '',
      city: '',
      street: '',
      street_number: '',
      country_code: '',
      postal_code: '',
    }
  }

  prepareData (hasShipping: boolean): CustomerDataType {
    // console.log('prepare Data')
    // console.log('localstorage trigger')
    // console.log('param is:' + hasShipping)
    if (hasShipping) {
      // console.log('hasshipping  true')
      // console.log('this.data.shipping_address')
      // console.log(this.data.shipping_address)
      localStorage.setItem('hasShipping', 'true')
      localStorage.setItem('shipping', JSON.stringify(this.data.shipping_address))
      localStorage.setItem('billing', JSON.stringify(this.data.billing_address))
    } else {
     //  console.log('hasshipping  false')
      localStorage.setItem('hasShipping', 'false')
      localStorage.setItem('shipping', 'false')
      localStorage.setItem('billing', JSON.stringify(this.data.billing_address))
    }

    return { ...this.data, ...(!hasShipping ? { shipping_address: null } : {}) }
  }

  @Watch('data', { deep: true })
  onDataChange (): void {
    console.log('localstorage trigger')
    if (this.data.shipping_address && this.data.shipping_address.city !== '') {
      console.log('hasshipping  true')
      localStorage.setItem('hasShipping', 'true')
      localStorage.setItem('shipping', JSON.stringify(this.data.shipping_address))
      localStorage.setItem('billing', JSON.stringify(this.data.billing_address))
    } else {
      console.log('hasshipping  false')
      localStorage.setItem('hasShipping', 'false')
      localStorage.setItem('billing', JSON.stringify(this.data.billing_address))
    }
    this.$emit('input', this.prepareData(this.hasShipping))
  }

  @Watch('hasShipping')
  onHasShippingChanged (hasShipping: boolean): void {
    if (hasShipping && !this.data.shipping_address) {
      localStorage.setItem('hasShipping', 'false')
      console.log('hasshipping  false onEvent')
      this.data.shipping_address = this.getEmptyAddress()
    }

    this.$emit('input', this.prepareData(hasShipping))
  }
}
