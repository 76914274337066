
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroupHint from '@/components/FormGroupHint.vue'

@Component({
  name: 'FormGroup',
  components: { FormGroupHint },
  inheritAttrs: false,
})
export default class FormGroup extends Vue {
  @Prop({ default: null }) private label!: string|null;
  @Prop({ default: true }) private show!: boolean;
  @Prop({ default: () => ({}) }) private model!: any;
  @Prop({ default: 1 }) private maxHints!: number;
  @Prop({ default: () => [] }) private hints!: string[];

  get translations (): Record<string, any> {
    return {
      required: this.$t('validation_required_error'),
      requiredIf: this.$t('validation_required_error'),
      email: this.$t('validation_email_error'),
      sameAsPassword: this.$t('validation_password_not_match_error'),
      minLength: this.$t('validation_too_short_error'),
      maxLength: this.$t('validation_too_long_error'),
      minValue: this.$t('validation_incorrect_value_error'),
      maxValue: this.$t('validation_incorrect_value_error'),
      numeric: this.$t('validation_not_number_error'),
      money: this.$t('validation_incorrect_value_error'),
      integer: this.$t('validation_not_number_error'),
      date: this.$t('validation_date_error'),
      isIban: this.$t('validation_iban_error'),
      postalCode: this.$t('validation_postal_code_error'),
    }
  }

  get isInvalid (): boolean {
    return this.show && this.messagesList.length > 0
  }

  get messagesList (): string[] {
    if (!this.model.$params) {
      return this.hints
    }

    return Object.keys(this.model.$params as Record<string, any>).reduce((messages, rule) => {
      if (typeof this.model[rule] !== 'undefined' && this.model[rule] === false) {
        messages.push(this.translations[rule] || this.$t(rule))
      }

      return messages
    }, [...this.hints]).slice(0, this.maxHints)
  }
}
