var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "v-select",
      class: { disabled: _vm.disabled, danger: _vm.danger },
      attrs: { "aria-disabled": _vm.disabled },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class: { "no-border": _vm.opened },
          attrs: { tabindex: _vm.tabindex },
          on: {
            focusout: function ($event) {
              return _vm.toggle(false)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.onPressDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.onPressUp.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.onPressEnter.apply(null, arguments)
              },
            ],
          },
        },
        [
          _c(
            "div",
            {
              ref: "select",
              staticClass: "input-block",
              on: {
                click: function ($event) {
                  return _vm.toggle(null)
                },
              },
            },
            [
              _c("div", { staticClass: "select-title" }, [
                _vm._v(_vm._s(_vm.selectedTitle)),
              ]),
              _c("div", { staticClass: "caret" }, [
                _c(
                  "svg",
                  {
                    class: { opened: _vm.opened },
                    attrs: {
                      width: "18",
                      height: "11",
                      viewBox: "0 0 18 11",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1 1L9 9L17 1",
                        stroke: _vm.disabled ? "#9B9C9E" : "#0A0082",
                        "stroke-width": "2",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.opened,
                  expression: "opened",
                },
              ],
              ref: "dropdown",
              staticClass: "options-block",
            },
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "div",
                {
                  key: `${option.value}${option.title}`,
                  staticClass: "option",
                  class: {
                    "option-selected": _vm.selected === option.value,
                    "option-focused": _vm.focused === index,
                  },
                  attrs: { value: option.value },
                  on: {
                    click: function ($event) {
                      return _vm.select(option.value)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.title) + " ")]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          ref: "input",
          staticClass: "select-input",
          attrs: {
            "aria-disabled": _vm.disabled,
            autocomplete: _vm.autocomplete,
            tabindex: "-1",
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c("option", {
            key: `${option.value}${option.title}`,
            domProps: { value: option.value },
          })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }