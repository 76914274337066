// mixins.js
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class Uid extends Vue {
  get uid (): string {
    return Math.random().toString(10)
  }
}
