var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "loader-wrapper",
      class: { loading: _vm.loading, tall: _vm.loading && _vm.hidden },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading-spinner" },
            [_c("VLoader", { attrs: { size: _vm.size } })],
            1
          )
        : _vm._e(),
      !_vm.loading || !_vm.hidden
        ? _c("div", { staticClass: "slot-content" }, [_vm._t("default")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }