import Vue from 'vue'
import VueI18n from 'vue-i18n'

const fallbackLocale = 'en'

Vue.use(VueI18n)

/**
 * This is a stub function to mark translations key for VueI18NExtract.
 * Note: The key will not be translated.
 */
export const t = (key: string): string => key

export const i18n: VueI18n = new VueI18n({
  locale: fallbackLocale,
  fallbackLocale,
  silentFallbackWarn: true,
})
