import { render, staticRenderFns } from "./Loadable.vue?vue&type=template&id=6a1c1120&scoped=true"
import script from "./Loadable.vue?vue&type=script&lang=ts"
export * from "./Loadable.vue?vue&type=script&lang=ts"
import style0 from "./Loadable.vue?vue&type=style&index=0&id=6a1c1120&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1c1120",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/rpfe.ratepay.demoshop.rocks/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a1c1120')) {
      api.createRecord('6a1c1120', component.options)
    } else {
      api.reload('6a1c1120', component.options)
    }
    module.hot.accept("./Loadable.vue?vue&type=template&id=6a1c1120&scoped=true", function () {
      api.rerender('6a1c1120', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Loadable.vue"
export default component.exports