var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "action-bar",
      class: { "no-back": !_vm.back, "with-agreement": _vm.showAgreement },
    },
    [
      _c("div", { staticClass: "agreement-block" }, [
        _vm.showAgreement
          ? _c("div", {
              staticClass: "agreement",
              domProps: { innerHTML: _vm._s(_vm.$t("confirmation_terms")) },
            })
          : _vm._e(),
      ]),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "div",
          { staticClass: "button-wrapper" },
          [
            _vm.back
              ? _c(
                  "VButton",
                  {
                    staticClass: "back-button",
                    attrs: {
                      id: "backButton",
                      type: "secondary",
                      "custombuttonbackgroundcolor-data":
                        _vm.custombuttonbackgroundcolorData,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("back")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.back))]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-wrapper" },
          [
            _vm.next
              ? _c(
                  "VButton",
                  {
                    attrs: {
                      id: "forwardButton",
                      type: "primary",
                      disabled: !_vm.allValid,
                      "custombuttonbackgroundcolor-data":
                        _vm.custombuttonbackgroundcolorData,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("next")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.next))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }