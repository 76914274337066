var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "alert", class: _vm.type }, [
    _vm.icon
      ? _c(
          "div",
          { staticClass: "icon" },
          [_c("VIcon", { attrs: { fill: _vm.fill, name: _vm.icon } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "content", style: { color: _vm.fill } }, [
      _c(
        "div",
        { staticClass: "message", class: { "font-semibold": _vm.details } },
        [_vm._v(_vm._s(_vm.message))]
      ),
      _vm.details
        ? _c("div", {
            staticClass: "details",
            domProps: { innerHTML: _vm._s(_vm.details) },
          })
        : _vm._e(),
    ]),
    _vm.showClose
      ? _c(
          "div",
          { staticClass: "close-button", on: { click: _vm.close } },
          [_c("VIcon", { attrs: { fill: _vm.fill, name: "close" } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }