
import InstallmentCalculationsSummary from '@/views/PaymentPage/InstallmentCalculationsSummary.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VInput from '@/components/VInput.vue'
import VCheckbox from '@/components/VCheckbox.vue'
import FormGroup from '@/components/FormGroup.vue'
import VSelect from '@/components/VSelect.vue'
import { InstallmentCalculationType } from '@/types/checkout'
import { calculateInstallment } from '@/api/checkout'
import Loadable from '@/components/Loadable.vue'
import InstallmentMonthsForm from '@/views/PaymentPage/InstallmentMonthsForm.vue'

@Component({
  name: 'InstallmentCalculations',
  components: {
    InstallmentMonthsForm,
    Loadable,
    VSelect,
    FormGroup,
    VCheckbox,
    VInput,
    InstallmentCalculationsSummary,
  },
})
export default class InstallmentCalculations extends Vue {
  @Prop() private token!: string
  @Prop() private currency!: string
  @Prop() private options!: string[]
  @Prop() private profile!: string

  @Prop() private backgroundcolorData!: string;

  calculation: null|InstallmentCalculationType = null
  isLoading = true
  months = this.options[0]
  installmentprofile = this.months.toString().split('_')[1]

  get hasData (): number|boolean {
    return this.calculation?.amount || false
  }

  get monthsModel (): string {
    return this.months
  }

  set monthsModel (months: string) {
    this.months = months
    this.installmentprofile = months.toString().split('_')[1]
    console.log('Calc:')
    console.log(months)
    this.checkInstallmentCalculation(months)
      .then(() => {
        console.log('Calc EMitting change')
        this.$emit('input', months)
      })
  }

  mounted (): void {
    console.log('InstallmentCalculations data:' + this.months + 'profile:' + this.profile)
    this.checkInstallmentCalculation(this.months)
      .then(() => {
        console.log('calc: emitting after mounted')
        console.log(this.months)
        this.$emit('input', this.months)
      })
  }

  checkInstallmentCalculation (months: string): Promise<void> {
    this.isLoading = true
    console.log('loading profile' + this.installmentprofile);

    return calculateInstallment(this.token, {
      installmentplan_by_months: parseInt(months, 10),
      profile: this.installmentprofile,
    })
      .then(({ data }: { data: InstallmentCalculationType }) => {
        this.calculation = data
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
