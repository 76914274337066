var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "address-form" }, [
    _c(
      "div",
      { staticClass: "form-wrapper" },
      [
        this.isShipping == "true"
          ? _c("FormGroup", {
              attrs: { model: _vm.$v.data.first_name },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c("VInput", {
                          attrs: {
                            disabled: _vm.edit_customer,
                            label: _vm.$t("input_first_name"),
                            danger: invalid,
                          },
                          model: {
                            value: _vm.data.first_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "first_name", $$v)
                            },
                            expression: "data.first_name",
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                3985912241
              ),
            })
          : _vm._e(),
        this.isShipping == "true"
          ? _c("FormGroup", {
              attrs: { model: _vm.$v.data.last_name },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c("VInput", {
                          attrs: {
                            disabled: _vm.edit_customer,
                            label: _vm.$t("input_last_name"),
                            danger: invalid,
                          },
                          model: {
                            value: _vm.data.last_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "last_name", $$v)
                            },
                            expression: "data.last_name",
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                1521897905
              ),
            })
          : _vm._e(),
        this.isShipping == "true"
          ? _c("FormGroup", {
              attrs: { model: _vm.$v.data.company },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c("VInput", {
                          attrs: {
                            disabled: _vm.edit_customer,
                            label: _vm.$t("input_company"),
                            danger: invalid,
                          },
                          model: {
                            value: _vm.data.company,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "company", $$v)
                            },
                            expression: "data.company",
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                3641395249
              ),
            })
          : _vm._e(),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.street },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_street"),
                      danger: invalid,
                      autocomplete: "street-address",
                    },
                    model: {
                      value: _vm.data.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "street", $$v)
                      },
                      expression: "data.street",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.street_number },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_number"),
                      danger: invalid,
                    },
                    model: {
                      value: _vm.data.street_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "street_number", $$v)
                      },
                      expression: "data.street_number",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.street_additional },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_street_additional"),
                      danger: invalid,
                    },
                    model: {
                      value: _vm.data.street_additional,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "street_additional", $$v)
                      },
                      expression: "data.street_additional",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.city },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_city"),
                      autocomplete: "address-level2",
                      danger: invalid,
                    },
                    model: {
                      value: _vm.data.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "city", $$v)
                      },
                      expression: "data.city",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.postal_code },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_postal_code"),
                      mask: _vm.postalCodeFormat,
                      autocomplete: "postal-code",
                      danger: invalid,
                    },
                    model: {
                      value: _vm.data.postal_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "postal_code", $$v)
                      },
                      expression: "data.postal_code",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _vm.countries.length > 1
          ? _c("FormGroup", {
              attrs: {
                label: _vm.$t("input_country_code"),
                model: _vm.$v.data.country_code,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c("VSelect", {
                          attrs: {
                            disabled: _vm.edit_customer,
                            options: _vm.countryCodes,
                            autocomplete: "country",
                            danger: invalid,
                          },
                          model: {
                            value: _vm.data.country_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "country_code", $$v)
                            },
                            expression: "data.country_code",
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                3691855797
              ),
            })
          : _c(
              "FormGroup",
              {
                attrs: {
                  disabled: _vm.edit_customer,
                  label: _vm.$t("input_country_code"),
                  model: _vm.$v.data.country_code,
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t(`country_${_vm.countries[0]}`)) + " "
                ),
              ]
            ),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }