
import { Component, Prop, Vue } from 'vue-property-decorator'
import helpers from '@/helpers'
import { PaymentDetailsType, PaymentMethodResponseType } from '@/types/checkout'

@Component({ name: 'PaymentMethodPrepayment' })
export default class PaymentMethodPrepayment extends Vue {
  @Prop() private value!: PaymentDetailsType;
  @Prop() private response!: PaymentMethodResponseType;

  mounted (): void {
    this.$emit('input', {})
  }

  get amount (): string {
    return helpers.money(this.response.basket_amount, this.response.currency)
  }

  get days (): number {
    return this.response.prepayment_reservation_days
  }
}
