var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "v-loader",
      attrs: {
        viewBox: "0 0 100 100",
        width: _vm.size,
        height: _vm.size,
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("mask", { attrs: { id: `circle-mask-${_vm.uid}` } }, [
        _c("circle", {
          attrs: {
            fill: "none",
            cx: "50",
            cy: "50",
            r: "42",
            stroke: "white",
            "stroke-width": "13",
          },
        }),
      ]),
      _c("rect", {
        staticClass: "area",
        attrs: {
          width: "100",
          height: "100",
          mask: `url(#circle-mask-${_vm.uid})`,
        },
      }),
      _c(
        "g",
        {
          staticClass: "rotating-loading",
          attrs: { mask: `url(#circle-mask-${_vm.uid})` },
        },
        [
          _c("circle", {
            staticClass: "scaling-loading",
            attrs: { cx: "50", cy: "0", r: "20" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }