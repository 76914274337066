
import helpers from '@/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InstallmentCalculationType } from '@/types/checkout'

@Component({
  name: 'InstallmentCalculationsSummary',
})
class InstallmentCalculationsSummary extends Vue {
  @Prop({ required: true }) private calculation!: InstallmentCalculationType
  @Prop({ required: true }) private currency!: string

  money (amount: number): string {
    return helpers.money(amount, this.currency)
  }
}

const __default__ = InstallmentCalculationsSummary
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "5b8d1a37-backgroundColor2": (_vm.backgroundColor2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__