
import { Component, Prop, Vue } from 'vue-property-decorator'
import VIcon from '@/components/VIcon.vue'

export enum AlertType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

@Component({
  name: 'Alert',
  components: { VIcon },
})
export default class Alert extends Vue {
  @Prop({ required: true }) private message!: string
  @Prop() private details!: string
  @Prop({ default: null }) private icon!: string
  @Prop({ default: false }) private showClose!: boolean
  @Prop({ default: AlertType.INFO }) private type!: AlertType

  close (): void {
    this.$emit('close')
  }

  get fill (): string {
    return ['warning', 'info'].includes(this.type) ? '#000' : '#fff'
  }
}
