
import { Component, Vue } from 'vue-property-decorator'
import NotificationsList from '@/components/NotificationsList.vue'

@Component({
  name: 'MainLayout',
  components: { NotificationsList },
})
export default class MainLayout extends Vue {
}
