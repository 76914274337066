
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Loading elements are used when data is loading. Use the loading-spinner
 * if there is data loading of indefinite amount.
 */
@Component({
  name: 'VLoader',
})
export default class VLoader extends Vue {
  @Prop({ default: 100 }) private size!: number|null;

  get uid (): string {
    return Math.random().toString().split('.')[1]
  }
}
