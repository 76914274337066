import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './services/localizator'
import { registerLayouts } from '@/layouts'
import { setup } from '@/setup'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

Vue.config.productionTip = false

setup(Vue)
registerLayouts(Vue)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
