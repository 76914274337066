
import { mask } from 'vue-the-mask'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VButton from '@/components/VButton.vue'

@Component({
  name: 'VInput',
  components: { VButton },
  inheritAttrs: false,
  directives: {
    mask (el, binding) {
      if (binding.value !== null) {
        // @ts-ignore: Doesn't require more params
        return mask.call(this, el, binding)
      }
    },
  },
})
export default class VInput extends Vue {
  @Prop({ default: null }) private label!: string|null;
  @Prop({ default: '' }) private value!: string|number;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: null }) private mask!: string|null;
  @Prop({ default: false }) private placeholder!: string|number;
  @Prop({ default: 0 }) private tabindex!: number;

  focused = false

  get attrs (): any {
    return this.$attrs
  }

  get listeners (): any {
    // eslint-disable-next-line
    const { input, ...listeners } = this.$listeners

    return listeners
  }

  get placeholderText (): any {
    if (!this.focused) {
      return ''
    }

    return this.placeholder
  }
}
