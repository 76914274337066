import { createDecorator, VueDecorator } from 'vue-class-component'
import store from '@/store'

export const Getter = (getterType: string): VueDecorator => {
  return createDecorator((options, key) => {
    if (!options.computed) options.computed = {}
    options.computed[key] = function () {
      return store.getters[getterType]
    }
  })
}
