
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'FormGroupHint',
  inheritAttrs: false,
})
export default class FormGroupHint extends Vue {
  @Prop({ required: true }) private hint!: string|null;
  @Prop({ default: 'error' }) private type!: string|null;
}
