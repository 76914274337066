
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BankDetailsType, CustomerDataType, PaymentDetailsType, PaymentMethodResponseType } from '@/types/checkout'
import BankDetailsForm from '@/views/PaymentPage/BankDetailsForm.vue'

@Component({
  name: 'PaymentMethodDirectDebit',
  components: { BankDetailsForm },
})
export default class PaymentMethodDirectDebit extends Vue {
  @Prop() private value!: PaymentDetailsType;
  @Prop() private response!: PaymentMethodResponseType;
  @Prop() private customer!: CustomerDataType;

  get bankDetails (): BankDetailsType {
    return this.value
  }

  set bankDetails (details: BankDetailsType) {
    this.$emit('input', details)
  }
}
