
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VButton',
})
class ActionBar extends Vue {
  @Prop({ default: '' }) private type!: string;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop() private custombuttonbackgroundcolorData!: string;

  custombuttonbackgroundcolor = '#5c6eaf'

  mounted (): void {
    // console.log('Vbutton data:' + this.custombuttonbackgroundcolorData)
    this.custombuttonbackgroundcolor = this.custombuttonbackgroundcolorData ?? '#5c6eaf'
  }

  onClick (e: Event): void {
    if (this.disabled) {
      console.log('Button is disabled');
      return
    }
    console.log('Button is enabled');

    this.$emit('click', e)
  }
}

const __default__ = ActionBar
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "790e9d70-custombuttonbackgroundcolor": (_vm.custombuttonbackgroundcolor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__