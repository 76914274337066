var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", {
    staticClass: "v-icon",
    domProps: { innerHTML: _vm._s(_vm.svg) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }