
import { Component, Prop, Vue } from 'vue-property-decorator'
import VLoader from '@/components/VLoader.vue'

@Component({
  name: 'Loadable',
  components: { VLoader },
})
export default class Loadable extends Vue {
  @Prop({ required: true }) private loading!: boolean;
  @Prop({ required: false }) private hidden!: boolean;
  @Prop({ required: false, default: 100 }) private size!: number|null;
  @Prop({ default: null }) private text!: string|null;
}
