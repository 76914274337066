
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'StepsLine' })
export default class StepsLine extends Vue {
  @Prop({
    default: () => [],
  }) private steps!: string[];

  @Prop({ default: 1 }) private current!: number;

  @Prop() private customMenuFontData!: string;

  customMenuFont = ''

  mounted (): void {
    // console.log('Steps Line data:' + this.customMenuFontData)
    console.log('this.customMenuFont:' + this.customMenuFont)
    this.customMenuFont = this.customMenuFontData
  }
}
