import { EventBus } from '@/services/event-bus'
import { i18n } from '@/services/localizator'
import { AxiosError, AxiosResponse } from 'axios'

export enum NotificationType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface NotificationPayloadType extends AxiosError {
  text: string,
  response: AxiosResponse,
  details: string|string[],
}

export interface ErrorResponseDataType {
  info: string,
  source: string,
}

export interface NotificationParamsType {
  timeout: number,
}

let interval:number
let url:string

const getMessage = (payload: NotificationPayloadType|string): string => {
  console.log('Exception Payload Response:')
  if (typeof payload === 'string') {
    console.log('payload is string')

    return payload
  } else if (payload.text) {
    console.log('payload is text')

    return payload.text
  } else if (payload?.response?.data?.message) {
    const error = payload.response.data.message
    console.log(payload.response)
    if (typeof payload.response.status !== 'undefined' && payload.response.status === 406) {
      const forwardButton = <HTMLButtonElement>document.getElementById('forwardButton')
      const backButton = <HTMLButtonElement>document.getElementById('backButton')
      if (typeof forwardButton !== 'undefined' && forwardButton !== null) {
        console.log('Setting forward Button to Disabled')
        forwardButton.disabled = true
      } else {
        console.log('unchanged forwardButton state')
      }

      if (typeof backButton !== 'undefined' && backButton !== null) {
        console.log('Setting forward Button to Disabled')
        backButton.disabled = true
      } else {
        console.log('unchanged backButton state')
      }

      console.log('Exception is invalid payments')
      console.log(payload.response.data)
      console.log('intervasl')
      console.log(payload.response.data.error_redirect_delay)
      interval = setInterval(myURL, parseInt(payload.response.data.error_redirect_delay) * 1000)
      console.log('url')
      console.log(payload.response.data.redirect_url)
      url = payload.response.data.redirect_url

      return ''
    }

    return i18n.t(error) as string
  }

  return i18n.t('general_error_message') as string
}

const getDetails = (payload: NotificationPayloadType|string): string => {
  if (typeof payload === 'string') {
    console.log('payload is striong , retruning')

    return ''
  }

  if (typeof payload === 'undefined') {
    console.log('payload undfined , retruning')

    return ''
  }

  console.log('payload')
  console.log(payload)
  if (payload.response.status === 406) {
    payload.details = payload.response.data.details
  }
  const details = typeof payload.details === 'string' ? [payload.details] : []

  /* if (payload?.response?.data?.errors) {
    payload.response.data.errors.forEach((error: ErrorResponseDataType) => details.push(i18n.t(error.info) as string))
  }
  */

  return details.join('<br>')
}

function myURL () {
  console.log('myUrl')
  console.log(url)
  document.location.href = url
  clearInterval(interval)
}

export const notification = {
  add (payload: NotificationPayloadType|string, type: NotificationType|undefined, icon: string|null = null, params: NotificationParamsType|null): void {
    const message = getMessage(payload)
    const details = getDetails(payload)
    console.log('after getDetails(payload)')

    params = { timeout: 50000, ...(params || {}) }
    console.log('params:')
    console.log(params)
    EventBus.emit('notification:create', {
      notification: {
        message,
        details,
        type,
        icon,
      },
      params,
    })
  },

  error (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    console.log('error notify quee')
    console.log(text)
    notification.add(text, NotificationType.ERROR, 'error', params)
  },
  success (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.SUCCESS, 'check-circle', params)
  },
  warning (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.WARNING, 'warning', params)
  },
  info (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.INFO, 'info', params)
  },
}
