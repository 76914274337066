import { render, staticRenderFns } from "./PaymentMethodSelected.vue?vue&type=template&id=c60c4304&scoped=true"
import script from "./PaymentMethodSelected.vue?vue&type=script&lang=ts"
export * from "./PaymentMethodSelected.vue?vue&type=script&lang=ts"
import style0 from "./PaymentMethodSelected.vue?vue&type=style&index=0&id=c60c4304&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c60c4304",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/rpfe.ratepay.demoshop.rocks/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c60c4304')) {
      api.createRecord('c60c4304', component.options)
    } else {
      api.reload('c60c4304', component.options)
    }
    module.hot.accept("./PaymentMethodSelected.vue?vue&type=template&id=c60c4304&scoped=true", function () {
      api.rerender('c60c4304', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PaymentPage/PaymentMethodSelected.vue"
export default component.exports