
import { AddressType, CustomerDataType, FullCheckoutDataType } from '@/types/checkout'
import Address from '@/views/PaymentPage/Address.vue'
import BuyerDataEntry from '@/views/PaymentPage/BuyerDataEntry.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CustomerType } from '@/types/customer'

@Component({
  name: 'BuyerData',
  components: { Address, BuyerDataEntry },
})
export default class Buyer extends Vue {
  @Prop() private data!: CustomerDataType|FullCheckoutDataType;

  get address (): AddressType|null {
    return this.data.billing_address || null
  }

  get shippingAddress (): AddressType|null {
    console.log('Landing Page BuyerData:')
    console.log(this.data.shipping_address)

    return this.data.shipping_address || null
  }

  get customerData (): CustomerType|null {
    return this.data.customer
  }

  mounted (): void {
    // console.log(this.data)
  }
}
