var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "installment-months-form" }, [
    _c(
      "div",
      { staticClass: "form-wrapper" },
      [
        _c("VSelect", {
          attrs: {
            options: _vm.preparedOptions,
            selected: _vm.months,
            value: _vm.months,
            label: _vm.$t("months_select_label"),
          },
          model: {
            value: _vm.months,
            callback: function ($$v) {
              _vm.months = $$v
            },
            expression: "months",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }