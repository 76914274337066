var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "installment-calculation-result" },
    [
      _c(
        "Loadable",
        {
          attrs: {
            loading: _vm.isLoading,
            text: _vm.$t("calculating"),
            hidden: true,
            size: 60,
          },
        },
        [
          _c("div", { staticClass: "selector-form" }, [
            _c("div", [_vm._t("default")], 2),
            _c(
              "div",
              [
                _c("InstallmentMonthsForm", {
                  staticClass: "rate-select",
                  attrs: { options: _vm.options },
                  model: {
                    value: _vm.monthsModel,
                    callback: function ($$v) {
                      _vm.monthsModel = $$v
                    },
                    expression: "monthsModel",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.calculation && _vm.hasData
            ? _c("InstallmentCalculationsSummary", {
                attrs: {
                  calculation: _vm.calculation,
                  currency: _vm.currency,
                  "backgroundcolor-data": _vm.backgroundcolorData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }