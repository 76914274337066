
import { Getter } from '@/decorators/Getter'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VButton from '@/components/VButton.vue'

@Component({
  name: 'ActionBar',
  components: { VButton },
})
export default class ActionBar extends Vue {
  @Prop({ default: false }) private back!: string|boolean;
  @Prop({ default: false }) private next!: string|boolean;
  @Prop({ default: false }) private showAgreement!: boolean;
  @Prop() private custombuttonbackgroundcolorData!: string;
  @Prop({ default: '#fff' }) private backgroundColorData!: string;

  @Getter('checkout/allValid') allValid!: boolean

  mounted (): void {
    console.log('Actionbar.vue: backgroundcolor:' + this.backgroundColorData)
  }
}

