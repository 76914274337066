
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class VCheckbox extends Vue {
  @Prop({ default: null }) private label!: string|number|null;
  @Prop({ default: null }) private value!: string|Record<string, unknown>|boolean|null;
  @Prop({ default: null }) private checked!: string[]|number[]|boolean|null|undefined;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: false }) private oneline!: boolean;
  @Prop({ default: null }) private valueAttr!: string|null;
  @Prop({ default: null }) private id!: string|null;
  @Prop({ default: 0 }) private tabindex!: number|null;

  selected = this.checked

  get computedId (): string {
    return this.id || `rp-check-${Math.random()}`
  }

  get isChecked (): boolean {
    if (this.isBoolean) {
      return !!this.checked
    }

    return this.checkedValues.includes(this.storeValue)
  }

  get checkedValues (): any[] {
    const checked = this.checked as string[]|number[]

    return checked ? (checked as Array<string|number>).map((c: any) => this.getStoreValue(c)) : []
  }

  get storeValue (): any {
    return this.getStoreValue(this.value)
  }

  get isBoolean (): boolean {
    return typeof this.checked === 'boolean'
  }

  get attrs (): any {
    return this.$attrs
  }

  // eslint-disable-next-line
  getStoreValue (value: any): any {
    return this.valueAttr !== null ? value[this.valueAttr] : value
  }

  onClick (): any {
    if (this.disabled) {
      return
    }

    if (this.isBoolean) {
      return this.$emit('change', !this.checked)
    }

    // @ts-ignore: Can't recognize checked type
    let buffer = [...this.checked]

    if (this.isChecked) {
      // @ts-ignore: Can't recognize checked type
      buffer = this.checked.filter(el => this.getStoreValue(el) !== this.storeValue)
    } else {
      buffer.push(this.value as string|number)
    }

    this.$emit('change', buffer)
  }
}
