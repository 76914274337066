
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Folder',
})
export default class Alert extends Vue {
  @Prop({ required: true }) private link!: string
  @Prop({ default: 0 }) private tabindex!: string

  isExpanded = false
}
