var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "payment-method-selected" }, [
    _c("div", { staticClass: "icon-method-selected" }, [
      _c(
        "svg",
        {
          staticClass: "bullet",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            height: "18px",
            viewBox: "0 0 24 24",
            width: "18px",
            fill: "#0a0082",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "selected-method" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              `payment_options_${_vm.getLabel(_vm.details.payment_method)}`
            )
          )
        ),
      ]),
    ]),
    _vm.getLabel(_vm.details.payment_method) ===
    _vm.$enum.PaymentMethod.INVOICE_BANK_TRANSFER
      ? _c("div", { staticClass: "method-info" }, [
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.$t("due_date_message")) },
          }),
        ])
      : _vm._e(),
    _vm.getLabel(_vm.details.payment_method) ===
    _vm.$enum.PaymentMethod.PREPAYMENT_BANK_TRANSFER
      ? _c("div", { staticClass: "method-info" }, [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("prepayment_reserved", {
                  days: _vm.data.prepayment_reservation_days,
                  amount: _vm.amount,
                })
              ),
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }