var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      key: _vm.state,
      staticClass: "payment-page",
      class: {
        "with-confirmation":
          _vm.state === _vm.$enum.CheckoutState.CHECKOUT_CONCLUSION,
      },
      style: {
        background: _vm.backgroundColor,
        color: _vm.textColor,
        "font-family": _vm.customFont,
        backgroundColor2: _vm.backgroundColor2,
        customMenuFont: _vm.customMenuFont,
      },
    },
    [
      !_vm.is404
        ? _c(
            "Loadable",
            {
              staticClass: "loader",
              attrs: { loading: _vm.isLoading, hidden: !_vm.editData },
            },
            [
              _c("div", { staticClass: "payment-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "custom-logo",
                    style: {
                      top: _vm.getLogoPositionY(),
                      left: _vm.getLogoPositionX(),
                      position: "relative",
                    },
                  },
                  [
                    this.customLogoUrl !== ""
                      ? _c("div", [
                          _c("img", { attrs: { src: _vm.getImgUrl() } }),
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "logo-bar" },
                            [
                              _c("img", {
                                staticClass: "logo",
                                attrs: {
                                  src: require("../assets/logo.svg"),
                                  alt: _vm.$t("ratepay"),
                                },
                              }),
                              _c("MerchantLogo"),
                            ],
                            1
                          ),
                        ]),
                  ]
                ),
                this.moto
                  ? _c("div", { staticClass: "moto" }, [
                      _vm._v(" " + _vm._s(_vm.$t("moto_message")) + " "),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "info-block" }, [
                  _c(
                    "div",
                    { staticClass: "data-wrapper" },
                    [
                      _vm.state === _vm.$enum.CheckoutState.LANDING_PAGE
                        ? _c("StepLanding", {
                            attrs: { "checkout-data": _vm.editData },
                          })
                        : _vm._e(),
                      _vm.state === _vm.$enum.CheckoutState.CUSTOMER_DETAILS
                        ? _c("StepCustomerData", {
                            attrs: {
                              "checkout-data": _vm.editData,
                              edit_customer: _vm.edit_customer,
                            },
                            model: {
                              value: _vm.editData,
                              callback: function ($$v) {
                                _vm.editData = $$v
                              },
                              expression: "editData",
                            },
                          })
                        : _vm._e(),
                      _vm.state === _vm.$enum.CheckoutState.PAYMENT_METHODS
                        ? _c("StepPaymentMethod", {
                            attrs: {
                              "customer-data": _vm.customer,
                              "checkout-data": _vm.checkoutData,
                              "moto-data": _vm.motoGlobal,
                            },
                            on: {
                              update: function ($event) {
                                return _vm.onUpdate($event)
                              },
                            },
                            model: {
                              value: _vm.editData,
                              callback: function ($$v) {
                                _vm.editData = $$v
                              },
                              expression: "editData",
                            },
                          })
                        : _vm._e(),
                      _vm.state === _vm.$enum.CheckoutState.CHECKOUT_CONCLUSION
                        ? _c("StepConfirmation", {
                            attrs: { "checkout-data": _vm.editData },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "steps-block" },
                    [
                      _c("StepsLine", {
                        attrs: { steps: _vm.steps, current: _vm.currentStep },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "action-bar-wrapper" },
                [
                  _c(
                    "ActionBar",
                    _vm._b(
                      {
                        staticClass: "action-bar",
                        attrs: {
                          "custombuttonbackgroundcolor-data":
                            _vm.customButtonBackgroundColor,
                          "background-color-data": _vm.backgroundColor,
                        },
                        on: { back: _vm.goBack, next: _vm.goNext },
                      },
                      "ActionBar",
                      _vm.actionbarConfig[_vm.state],
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          )
        : _c("div", { staticClass: "error" }, [
            _vm._v(" " + _vm._s(_vm.$t("no_such_payment")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }