var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "payment-method-installment-bank-transfer" },
    [
      _c(
        "InstallmentCalculations",
        {
          attrs: {
            token: _vm.$route.params.token,
            currency: _vm.response.currency,
            options: _vm.getOption(_vm.response.installment_config),
            profile: _vm.profile,
          },
          on: { input: _vm.selectMonths },
        },
        [
          _c("div", { staticClass: "installment-info" }, [
            _vm._v(_vm._s(_vm.$t("installment_bank_transfer_info"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }