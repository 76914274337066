
import { Component, Prop, Vue } from 'vue-property-decorator'
import VButton from '@/components/VButton.vue'
import { createPopper, Modifier } from '@popperjs/core'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import { OptionType } from '@/types/forms'

@Component({
  name: 'VSelect',
  components: { VButton },
  inheritAttrs: false,
})
export default class VSelect extends Vue {
  @Prop({ default: null }) private label!: string|null;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: () => [] }) private options!: OptionType[];
  @Prop({ default: null }) private value!: any;
  @Prop({ default: 'off' }) private autocomplete!: string;
  @Prop({ default: 0 }) private tabindex!: number;

  opened = false
  selected: any = this.value
  popper: any = null
  focused = this.options.findIndex((entry) => entry.value === this.value)

  get attrs (): any {
    return this.$attrs
  }

  get listeners (): any {
    // eslint-disable-next-line
    const { input, ...listeners } = this.$listeners

    return listeners
  }

  get selectedTitle (): string {
    let selected = this.options.find(option => option.value === this.value)

    if (!selected) {
      selected = this.options.find(option => !option.value)
    }

    return selected ? selected.title || '' : ''
  }

  // eslint-disable-next-line
  select (value: any, close: boolean = true): void {
    this.selected = value
    this.$emit('input', value)
    if (close) {
      this.close()
    }
  }

  close (): void {
    this.opened = false
  }

  selectFocused (): void {
    if (this.opened) {
      return
    }

    this.select(this.options[this.focused].value, false)
  }

  onPressDown (): void {
    if (this.focused >= (this.options.length - 1)) {
      this.focused = 0
      this.selectFocused()

      return
    }
    this.focused++
    this.selectFocused()
  }

  onPressUp (): void {
    if (this.focused < 1) {
      this.focused = this.options.length - 1
      this.selectFocused()

      return
    }
    this.focused--
    this.selectFocused()
  }

  onPressEnter (): void {
    if (!this.opened) {
      this.toggle(true)

      return
    }
    this.select(this.options[this.focused].value)
  }

  toggle (state: boolean|null = null): void {
    if (this.disabled) {
      return
    }

    this.getDropdown()
      .then(popper => {
        this.opened = state === null ? !this.opened : state

        popper.update()
      })
  }

  async getDropdown (): Promise<any> {
    if (this.popper) {
      return this.popper
    }

    const { select, dropdown } = this.$refs as { select: HTMLElement, dropdown: HTMLElement }

    const setFullWidth = {
      name: 'setFullWidth',
      enabled: true,
      phase: 'write',
      fn (data: any) {
        const { state } = data
        state.elements.popper.style.width = `${state.rects.reference.width}px`
      },
    } as Partial<Modifier<any, any>>

    this.popper = createPopper(select, dropdown, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [flip, preventOverflow, setFullWidth],
    })

    return this.popper
  }

  beforeDestroy (): void {
    this.getDropdown().then(() => {
      this.popper.destroy()
      this.popper = null
    })
  }
}
