
import { Mutation } from '@/decorators/Mutation'
import { Uid } from '@/mixins/Uid'
import { postalCode } from '@/services/validator'
import { Mutations } from '@/store/modules/checkout'
import { MutationType } from '@/types/general'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { AddressType } from '@/types/checkout'
import VInput from '@/components/VInput.vue'
import VCheckbox from '@/components/VCheckbox.vue'
import VSelect from '@/components/VSelect.vue'
import FormGroup from '@/components/FormGroup.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

@Component({
  name: 'AddressForm',
  components: {
    FormGroup,
    VSelect,
    VCheckbox,
    VInput,
  },
  mixins: [validationMixin],
})
export default class AddressForm extends mixins(Uid) {
  @Prop() private value!: AddressType
  @Prop() private countries!: string[]
  @Prop() private isShipping!: string
  @Prop() public edit_customer!: boolean;

  @Mutation(`checkout/${Mutations.ADD_INVALID}`) addInvalid!: MutationType<string, void>
  @Mutation(`checkout/${Mutations.REMOVE_INVALID}`) removeInvalid!: MutationType<string, void>

  data: AddressType = { ...this.value }

  @Validations()
  validations (): Record<string, any> {
    if (this.isShipping == 'true'){
      return {
        data: {
          street: { required },
          street_number: { required },
          city: { required },
          postal_code: { required, postalCode: postalCode(this.data.country_code) },
          country_code: { required },
        },
      }
    } else {
      return {
        data: {
          street: { required },
          street_number: { required },
          city: { required },
          postal_code: { required, postalCode: postalCode(this.data.country_code) },
          country_code: { required },
        },
      }
    }
  }

  mounted (): void {
    if (this.countries.length === 1) {
      this.data.country_code = this.countries[0]
    }
    console.log('is_shipping:' + this.isShipping)
    this.handleValid()
  }

  @Watch('data', { deep: true })
  onDataChange (data: AddressType): void {
    this.handleValid()
    this.$emit('input', data)
  }

  get countryCodes (): Record<string, any>[] {
    return [
      { value: null, title: this.$t('please_select_option') },

    ].concat(this.countries.map((country: any) => {
      return { title: this.$t(`country_${country}`), value: country }
    }))
  }

  get postalCodeFormat (): string {
    const formatsMap: Record<string, string> = {
      DE: '#####',
      AT: '####',
      CH: '####',
      NL: '#### AA',
    }

    return formatsMap[this.data.country_code]
  }

  handleValid (): void {
    this.$v.$invalid ? this.addInvalid('address' + this.uid) : this.removeInvalid('address' + this.uid)
  }

  beforeDestroy (): void {
    this.removeInvalid('address' + this.uid)
  }
}
