var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "customer-data-form" }, [
    _c(
      "div",
      { staticClass: "form-wrapper" },
      [
        _c("FormGroup"),
        _c("div"),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.first_name },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_first_name"),
                      danger: invalid,
                      autocomplete: "given-name",
                    },
                    model: {
                      value: _vm.data.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "first_name", $$v)
                      },
                      expression: "data.first_name",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.last_name },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_last_name"),
                      danger: invalid,
                      autocomplete: "family-name",
                    },
                    model: {
                      value: _vm.data.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "last_name", $$v)
                      },
                      expression: "data.last_name",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.date_of_birth },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_date_of_birth"),
                      placeholder: _vm.$t("ph_date_of_birth"),
                      mask: _vm.mask,
                      danger: invalid,
                      autocomplete: "bday",
                    },
                    model: {
                      value: _vm.data.date_of_birth,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "date_of_birth", $$v)
                      },
                      expression: "data.date_of_birth",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.phone },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_phone"),
                      danger: invalid,
                      autocomplete: "tel",
                    },
                    model: {
                      value: _vm.data.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "phone", $$v)
                      },
                      expression: "data.phone",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c("FormGroup", {
          attrs: { model: _vm.$v.data.email },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c("VInput", {
                    attrs: {
                      disabled: _vm.edit_customer,
                      label: _vm.$t("input_email"),
                      danger: invalid,
                      autocomplete: "email",
                    },
                    model: {
                      value: _vm.data.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "email", $$v)
                      },
                      expression: "data.email",
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _c(
          "FormGroup",
          [
            _c("VInput", {
              attrs: {
                disabled: _vm.edit_customer,
                label: _vm.$t("input_company_name"),
                autocomplete: "organization",
              },
              model: {
                value: _vm.data.company_name,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "company_name", $$v)
                },
                expression: "data.company_name",
              },
            }),
          ],
          1
        ),
        _vm.hasCompanyName
          ? _c(
              "FormGroup",
              [
                _c("VInput", {
                  attrs: {
                    disabled: _vm.edit_customer,
                    label: _vm.$t("input_vat_id"),
                    autocomplete: "disabled",
                  },
                  model: {
                    value: _vm.data.vat_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "vat_id", $$v)
                    },
                    expression: "data.vat_id",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }