import { DATE_FORMAT } from '@/constants/date-format'
import { parse } from 'date-fns'
import * as ibantools from 'ibantools'

export const isDate = (lang?:string) => (value?: string): boolean => {
  if (!value) {
    return true
  }
  if (value.length !== 10) {
    return false
  }
  let format = ''
  if (localStorage.getItem('lang')?.toLowerCase() === 'en') {
    format = DATE_FORMAT.DATE_EN
  } else {
    format = DATE_FORMAT.DATE
  }

  try {
    const res = parse(value, format, new Date())

    return !isNaN(res.getTime())
  } catch (e) {
    return false
  }
}

export const isIban = () => (value?: string): boolean => {
  if (!value) {
    return true
  }

  return ibantools.isValidIBAN(value)
}

/**
 * Validate if fields value is true, 1 yes or on.
 * Used for agreement checkboxes
 */
export const accepted = () => (value: string|boolean|number): boolean => {
  return [1, '1', true, 'true', 'yes', 'on'].includes(value)
}

export const postalCode = (countryCode?: string) => (value?: string): boolean => {
  if (!value || !countryCode) {
    return true
  }

  const codeMap: Record<string, RegExp> = {
    DE: /^\d{5}$/,
    AT: /^\d{4}$/,
    CH: /^\d{4}$/,
    NL: /^\d{4}\s[A-z]{2}$/,
  }

  return codeMap[countryCode].test(value)
}
