import { http } from '@/api/http/http-provider.ts'
import { AxiosPromise } from 'axios'
import { InstallmentCalculationConfigType } from '@/types/checkout'

export const getCheckoutData = (token: string): AxiosPromise => http.get(`/api/v1/checkouts/${token}`)

export const calculateInstallment = (token: string, payload: InstallmentCalculationConfigType): AxiosPromise =>
  http.post(`api/v1/checkouts/${token}/installments/plan`, payload)

export const goNextStep = (token: string, data: Record<string, any>): AxiosPromise => http.post(`/api/v1/checkouts/${token}/next`, data)
export const goPreviousStep = (token: string, data: Record<string, any>): AxiosPromise => http.post(`/api/v1/checkouts/${token}/previous`, data)
export const confirmPayment = (token: string): AxiosPromise => http.post(`/api/v1/checkouts/${token}/confirm`)

export default {
  getCheckoutData,
  goNextStep,
}
