
import Folder from '@/components/Folder.vue'
import { Mutation } from '@/decorators/Mutation'
import { Mutations } from '@/store/modules/checkout'
import { MutationType } from '@/types/general'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Uid } from '@/mixins/Uid'
import { BankDetailsType } from '@/types/checkout'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import VInput from '@/components/VInput.vue'
import VCheckbox from '@/components/VCheckbox.vue'
import VSelect from '@/components/VSelect.vue'
import FormGroup from '@/components/FormGroup.vue'
import { isIban, accepted } from '@/services/validator'

@Component({
  name: 'BankDetailsForm',
  components: {
    Folder,
    FormGroup,
    VCheckbox,
    VInput,
    VSelect,
  },
  mixins: [validationMixin],
  validations: {
    data: {
      iban: { required, isIban: isIban() },
      bank_account_holder: { required },
      sepa_consent: { required: accepted() },
    },
  },
})
export default class BankDetailsForm extends mixins(Uid) {
  @Prop() private value!: BankDetailsType
  @Prop() private customerName!: string
  @Prop() private companyName!: string
  @Prop({ default: false }) private isB2b!: boolean
  @Prop({ default: 'DE39RPY00000568463' }) private sepaNumber!: string|number
  @Prop({ default: 0 }) private tabindex!: number|null

  @Mutation(`checkout/${Mutations.ADD_INVALID}`) addInvalid!: MutationType<string, void>
  @Mutation(`checkout/${Mutations.REMOVE_INVALID}`) removeInvalid!: MutationType<string, void>

  useCustomerName = !this.isB2b
  data: BankDetailsType = {
    ...this.emptyData,
    ...this.value,
  }

  mounted (): void {
    this.handleValid()
    this.$emit('input', this.data)
  }

  get emptyData (): BankDetailsType {
    if (this.isB2b) {
      return {
        bank_account_holder: '',
        iban: '',
        sepa_consent: false,
      }
    } else {
      return {
        bank_account_holder: this.customerName,
        iban: '',
        sepa_consent: false,
      }
    }
  }

  get customernames (): { title: any, value: any }[] {
    if (this.isB2b) {
      return [
        { title: this.customerName, value: this.customerName },
        { title: this.companyName, value: this.companyName },
      ]
    }

    return [
      { title: this.customerName, value: this.customerName },
    ]
  }

  updateCustomerName (use: boolean): void {
    // console.log('updateCustomername:' + use)
    if (use) {
      this.data.bank_account_holder = this.customerName
    }
  }

  get isCustomerName (): boolean {
    return this.useCustomerName
  }

  set isCustomerName (use: boolean) {
    // console.log('setupdateCustomername:' + use)
    this.useCustomerName = use
    this.updateCustomerName(use)
  }

  @Watch('data', { deep: true })
  onDataChange (data: BankDetailsType): void {
    // console.log('Datachange:' + data)
    this.handleValid()
    this.$emit('input', data)
  }

  handleValid (): void {
    this.$v.$invalid ? this.addInvalid('bank' + this.uid) : this.removeInvalid('bank' + this.uid)
  }

  beforeDestroy (): void {
    this.removeInvalid('bank' + this.uid)
  }

  get uid (): string {
    return Math.random().toString(10)
  }
}
