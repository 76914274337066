var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "bank-details-form" },
    [
      _c(
        "div",
        { staticClass: "form-wrapper" },
        [
          _c(
            "FormGroup",
            [
              _c("VSelect", {
                attrs: {
                  label: _vm.$t("input_bank_account_holder"),
                  options: _vm.customernames,
                  disabled: _vm.useCustomerName,
                  autocomplete: "cc-name",
                },
                model: {
                  value: _vm.data.bank_account_holder,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "bank_account_holder", $$v)
                  },
                  expression: "data.bank_account_holder",
                },
              }),
            ],
            1
          ),
          _vm.isB2b
            ? [
                _c(
                  "FormGroup",
                  { staticClass: "checkbox-block" },
                  [
                    _c("VCheckbox", {
                      attrs: { label: _vm.$t("input_use_customer_name") },
                      model: {
                        value: _vm.isCustomerName,
                        callback: function ($$v) {
                          _vm.isCustomerName = $$v
                        },
                        expression: "isCustomerName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("FormGroup", {
            staticClass: "iban-input",
            attrs: { model: _vm.$v.data.iban },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _c("VInput", {
                      attrs: {
                        label: _vm.$t("input_iban"),
                        danger: invalid,
                        autocomplete: "",
                      },
                      model: {
                        value: _vm.data.iban,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "iban", $$v)
                        },
                        expression: "data.iban",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("FormGroup", {
            staticClass: "sepa-consent",
            attrs: { model: _vm.$v.data.sepa_consent },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _c("VCheckbox", {
                      attrs: {
                        label: _vm.$t("input_sepa_consent"),
                        danger: invalid,
                      },
                      model: {
                        value: _vm.data.sepa_consent,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "sepa_consent", $$v)
                        },
                        expression: "data.sepa_consent",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "Folder",
        {
          staticClass: "sepa-info",
          attrs: { link: _vm.$t("sepa_agreement_link") },
        },
        [
          _vm.isB2b
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("sepa_informtion_b2b", { number: _vm.sepaNumber })
                  ),
                },
              })
            : _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("sepa_informtion_b2c", { number: _vm.sepaNumber })
                  ),
                },
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }