var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "steps-line" }, [
    _c(
      "h2",
      { staticClass: "title", style: { "font-family": _vm.customMenuFont } },
      [_vm._v(_vm._s(_vm.$t("steps_title")))]
    ),
    _c(
      "div",
      {
        staticClass: "steps",
        class: { small: _vm.steps.length < 3 },
        style: { "font-family": _vm.customMenuFont },
      },
      _vm._l(_vm.steps, function (step, index) {
        return _c(
          "div",
          {
            key: step,
            staticClass: "step",
            class: {
              seen: index < _vm.current,
              unseen: index > _vm.current,
              current: index === _vm.current,
            },
          },
          [
            _c("div", { staticClass: "step-oval" }, [
              _vm._v(_vm._s(index + 1)),
            ]),
            _c("div", { staticClass: "step-title" }, [
              _vm._v(_vm._s(_vm.$t(`step_${step}`))),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }