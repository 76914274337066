import { DATE_FORMAT } from '@/constants/date-format'
import { format as formatDate, parse as parseDate, parseISO } from 'date-fns'

/**
 * Formats date
 */
export const date = (date: string, format: string = DATE_FORMAT.DATE, from: string = DATE_FORMAT.SQL_DATE_TIME): string => {
  return !date ? '' : formatDate(parseDate(date, from, new Date()), format)
}

/**
 * Formats ISO date
 */
export const isoDate = (date: string, format: string = DATE_FORMAT.DATE): string => {
  return formatDate(parseISO(date), format)
}

/**
 * Format money value
 */
export const money = (amount: string|number, currency = 'EUR', locale = 'de-DE', options = {}): string => {
  return Number(amount).toLocaleString(locale, {
    style: 'currency',
    currency,
    ...options,
  })
}

export default {
  date,
  isoDate,
  money,
}
