
import { Component, Prop, Vue } from 'vue-property-decorator'

// TODO: Implement proper icons
@Component({
  name: 'VIcon',
})
export default class VIcon extends Vue {
  @Prop({ required: true }) private name!: string;
  @Prop({ default: '#000' }) private fill!: string;

  get svg (): string {
    const icons: Record<string, string> = {
      bullet: `<svg viewBox="0 0 30 30" height="30" width="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4443 22.2153L11.4437 22.2153C8.53304 22.0293 6.74975 20.765 5.71672 19.0253C4.64009 17.2121 4.28285 14.7101 4.65803 11.9902C5.02982 9.29482 6.03313 7.34142 7.46315 6.19491C8.85879 5.07596 10.891 4.54149 13.7495 5.13562C16.1497 5.63909 18.3595 6.75136 19.8536 8.31616C21.3145 9.84618 22.1183 11.8252 21.7812 14.269C21.0969 19.2301 16.1709 22.519 11.4443 22.2153Z" fill="${this.fill}" stroke="${this.fill}" stroke-width="3"/><path d="M12.5043 16.18C13.5123 16.18 14.2863 15.37 14.2863 14.416C14.2863 13.444 13.5123 12.652 12.5043 12.652C11.4963 12.652 10.7223 13.444 10.7223 14.416C10.7223 15.37 11.4963 16.18 12.5043 16.18Z" fill="white"/></svg>`,
      info: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: ${this.fill}" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>`,
      warning: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: ${this.fill}" viewBox="0 0 24 24"><path d="M4.47 20h15.06c1.54 0 2.5-1.67 1.73-3L13.73 3.99c-.77-1.33-2.69-1.33-3.46 0L2.74 17c-.77 1.33.19 3 1.73 3zM12 13c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"/></svg>`,
      error: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: ${this.fill}" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>`,
      'check-circle': `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: ${this.fill}" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>`,
      close: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: ${this.fill}" viewBox="0 0 24 24"><path d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z"/></svg>`,
    }

    return icons[this.name]
  }
}
