var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "customer" },
    [
      _vm._t("default", null, {
        data: `${_vm.customer.first_name || ""} ${
          _vm.customer.last_name || ""
        }`,
      }),
      _vm._t("default", null, { data: _vm.customer.date_of_birth || "" }),
      _vm._t("default", null, { data: _vm.customer.phone || "" }),
      _vm._t("default", null, { data: _vm.customer.email || "" }),
      _vm._t("default", null, { data: `${_vm.customer.company_name || ""}` }),
      _vm._t("default", null, { data: `${_vm.customer.vat_id || ""}` }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }