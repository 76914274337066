
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FullCheckoutDataType, PaymentDetailsType } from '@/types/checkout'
import helpers from '@/helpers'
import VIcon from '@/components/VIcon.vue'

@Component({
  name: 'PaymentMethodSelected',
  components: { VIcon },
})
export default class PaymentMethodSelected extends Vue {
  @Prop() private data!: FullCheckoutDataType;

  getLabel (item : any) {
    var paymentMethod = item.replace(/_[0-9]/g, '')
    // console.log('Label:')
    // console.log(paymentMethod)

    return paymentMethod
  }

  get details (): PaymentDetailsType {
    console.log('DetailType:')
    console.log(this.data.payment_details)

    return this.data.payment_details
  }

  get amount (): string {
    return helpers.money(this.data.basket_amount, this.data.currency)
  }

  get months (): number|undefined {
    return this.details.installment?.total_months
  }

  get rate (): string {
    return helpers.money(this.details.installment?.plan.rate || 0, this.data.currency)
  }
}
