import { createDecorator, VueDecorator } from 'vue-class-component'
import store from '@/store'

export const Mutation = (mutationType: string): VueDecorator => {
  return createDecorator((options, key) => {
    if (!options.methods) options.methods = {}
    options.methods[key] = function (...payload) {
      return store.commit(mutationType, ...payload)
    }
  })
}
