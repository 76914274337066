var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "address" },
    [
      _vm._t("default", null, {
        data: `${_vm.address.first_name || ""} ${_vm.address.last_name || ""}`,
      }),
      _vm._t("default", null, { data: `${_vm.address.company || ""}` }),
      _vm._t("default", null, {
        data: `${_vm.address.street || ""} ${_vm.address.street_number || ""}`,
      }),
      _vm._t("default", null, {
        data: `${_vm.address.street_additional || ""}`,
      }),
      _vm._t("default", null, {
        data: `${_vm.address.postal_code || ""} ${_vm.address.city || ""}`,
      }),
      _vm._t("default", null, {
        data: _vm.$t(`country_${_vm.address.country_code}`) || "",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }