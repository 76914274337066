var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "step-payment-method" }, [
    _c("h3", { staticClass: "subtitle" }, [
      _vm._v(_vm._s(_vm.$t("payment_method_title"))),
    ]),
    _c(
      "div",
      { staticClass: "form-wrapper" },
      _vm._l(_vm.methods, function (entry) {
        return _c(
          "div",
          { key: entry, staticClass: "method-block" },
          [
            _c("VRadio", {
              attrs: {
                value: entry,
                label: _vm.getLabel(_vm.$t(`payment_options_${entry}`), entry),
              },
              model: {
                value: _vm.paymentOptionModel,
                callback: function ($$v) {
                  _vm.paymentOptionModel = $$v
                },
                expression: "paymentOptionModel",
              },
            }),
            _vm.paymentOption === entry
              ? _c(
                  "div",
                  { staticClass: "method-wrapper" },
                  [
                    _c(_vm.getComponentName(entry), {
                      tag: "component",
                      attrs: {
                        response: _vm.value,
                        "backgroundcolor-data": _vm.backgroundcolorData,
                        profile: _vm.getProfile(entry),
                      },
                      model: {
                        value: _vm.editDataModel,
                        callback: function ($$v) {
                          _vm.editDataModel = $$v
                        },
                        expression: "editDataModel",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }