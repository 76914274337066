var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "payment-method-invoice" }, [
    _c("p", {
      domProps: {
        innerHTML: _vm._s(_vm.$t("due_date_message", { days: _vm.days })),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }