var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "payment-method-direct-debit" },
    [
      _c("div", { staticClass: "withdraw-info" }, [
        _vm._v(_vm._s(_vm.$t("account_withdraw_info"))),
      ]),
      _c("BankDetailsForm", {
        attrs: {
          "customer-name": _vm.response.customer_name,
          "company-name": _vm.response.company_name,
          "is-b2b":
            _vm.response.customer_type === _vm.$enum.CustomerBusinessType.B2B,
        },
        model: {
          value: _vm.bankDetails,
          callback: function ($$v) {
            _vm.bankDetails = $$v
          },
          expression: "bankDetails",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }