import Vue from 'vue'
import Vuex from 'vuex'
import general from './modules/general'
import checkout from './modules/checkout'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    checkout,
  },
})
