var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.calculation
    ? _c("div", { staticClass: "installment-calculations-summary" }, [
        _c("div", { staticClass: "installment-info" }, [
          _c("div", { staticClass: "conditions" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("installment_conditions"))),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("condition_annual_rate", {
                    rate: _vm.calculation.annual_percentage_rate,
                  })
                )
              ),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("condition_number_of_rates_full", {
                    value: _vm.calculation.number_of_rates_full,
                  })
                )
              ),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("condition_service_charge", {
                    value: _vm.money(_vm.calculation.service_charge),
                  })
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "plan" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("installment_plan"))),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("plan_number_of_rates", {
                    parts: _vm.calculation.number_of_rates,
                    amount: _vm.money(_vm.calculation.rate),
                  })
                )
              ),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("plan_last_rate", {
                    value: _vm.money(_vm.calculation.last_rate),
                  })
                )
              ),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.$t("plan_due_date")))]),
          ]),
        ]),
        _c("div", { staticClass: "amounts" }, [
          _c("div", { staticClass: "amount order-amount" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("installment_order_amount")))]),
            _c("div", [_vm._v(_vm._s(_vm.money(_vm.calculation.amount)))]),
          ]),
          _c("div", { staticClass: "amount" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("installment_interests")))]),
            _c("div", [
              _vm._v(_vm._s(_vm.money(_vm.calculation.interest_amount))),
            ]),
          ]),
          _c("div", { staticClass: "amount" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("installment_rate")))]),
            _c("div", [_vm._v(_vm._s(_vm.calculation.interest_rate) + "%")]),
          ]),
          _c("div", { staticClass: "amount" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("installment_fees")))]),
            _c("div", [
              _vm._v(_vm._s(_vm.money(_vm.calculation.service_charge))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "total-amount" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("total_amount")))]),
          _c("div", [_vm._v(_vm._s(_vm.money(_vm.calculation.total_amount)))]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }