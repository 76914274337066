
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VRadio',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class VRadio extends Vue {
  @Prop({ default: null }) private label!: string|number|null;
  @Prop({ default: null }) private value!: string|boolean|null;
  @Prop({ default: null }) private checked!: boolean|null|undefined;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: null }) private id!: string|null;
  @Prop({ default: 0 }) private tabindex!: number|null;

  selected = this.checked

  get computedId (): string {
    return this.id || `rp-check-${Math.random()}`
  }

  get isChecked (): boolean {
    return this.value === this.checked
  }

  get attrs (): any {
    return this.$attrs
  }

  emit (): void {
    this.$emit('change', this.value)
  }

  onClick (): any {
    console.log('OnCLick triggered')
    if (this.disabled) {
      console.log('Button disabled, .. returning')

      return
    }

    this.emit()
  }

  onSelect (): void {
    console.log('onSelect Triggered')
    this.emit()
  }
}
