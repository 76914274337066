var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-input",
      class: { disabled: _vm.disabled, danger: _vm.danger },
      attrs: { "aria-disabled": _vm.disabled },
    },
    [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: _vm.mask,
                  expression: "mask",
                },
              ],
              staticClass: "input-field",
              class: { filled: _vm.value !== "" },
              attrs: {
                placeholder: _vm.placeholderText,
                disabled: _vm.disabled,
                tabindex: _vm.disabled ? -1 : _vm.tabindex,
                "aria-disabled": _vm.disabled,
              },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                focus: function ($event) {
                  _vm.focused = true
                },
                blur: function ($event) {
                  _vm.focused = false
                },
              },
            },
            "input",
            _vm.attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _vm.label
        ? _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }