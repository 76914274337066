
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentDetailsType, PaymentMethodResponseType } from '@/types/checkout'

@Component({ name: 'PaymentMethodInvoice' })
export default class PaymentMethodInvoice extends Vue {
  @Prop() private value!: PaymentDetailsType;
  @Prop() private response!: PaymentMethodResponseType;

  mounted (): void {
    this.$emit('input', {})
  }

  get days (): number {
    return 14
  }
}
