var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "not-found" }, [
      _c("h1", [_vm._v("404 Page Not Found")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }