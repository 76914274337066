import { tokenProvider } from '@/services/token-provider'
import { AxiosInstance, AxiosRequestConfig } from 'axios'

export const applyRequestInterceptor = (http: AxiosInstance): void => {
  http.interceptors.request.use((request: AxiosRequestConfig) => {
    request.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    const token = tokenProvider.getToken()

    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
    }

    return request
  })
}
