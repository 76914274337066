
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import NotificationsList from '@/components/NotificationsList.vue'

@Component({
  components: { NotificationsList },
  computed: {
    ...mapGetters('general', {
      languageLoaded: 'languageLoaded',
    }),
  },
})
export default class App extends Vue {
  languageLoaded!: boolean

  get layout (): string {
    if (typeof (this.$route) === 'undefined') {
      return 'MainLayout'
    }

    if (typeof (this.$route.meta) === 'undefined') {
      return 'MainLayout'
    }

    return this.$route.meta.layout || 'MainLayout'
  }

  get uniqueUrl (): string {
    return this.$route.fullPath.split('#')[0]
  }

  /**
   * Check if language is loaded. Otherwise i18n will show warnings.
   */
  get isLoaded (): boolean {
    return this.languageLoaded
  }
}
