import axios, { AxiosInstance } from 'axios'
import { applyRequestInterceptor } from '@/api/http/request-interceptor'
// import { applyResponseInterceptor } from '@/api/http/response-interceptor'

/**
 * @param {Object<axios>} axiosInstance
 * @private
 */
const _configure = (axiosInstance: AxiosInstance) => {
  // @ts-ignore
  axiosInstance.defaults.baseURL = window.config.VUE_APP_BACKEND_URI
}

const axiosInstance = axios.create()

_configure(axiosInstance)
applyRequestInterceptor(axiosInstance)
// applyResponseInterceptor(axiosInstance)

export const http = axiosInstance
