
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentDetailsType, PaymentMethodResponseType, CustomerDataType, FullCheckoutDataType } from '@/types/checkout'
import { PaymentMethod } from '@/constants/payment-method'
import VRadio from '@/components/VRadio.vue'
import PaymentMethodInvoice from '@/views/PaymentPage/PaymentMethodInvoice.vue'
import PaymentMethodDirectDebit from '@/views/PaymentPage/PaymentMethodDirectDebit.vue'
import PaymentMethodPrepayment from '@/views/PaymentPage/PaymentMethodPrepayment.vue'
import PaymentMethodInstallmentBankTransfer from '@/views/PaymentPage/PaymentMethodInstallmentBankTransfer.vue'
import PaymentMethodInstallmentDirectDebit from '@/views/PaymentPage/PaymentMethodInstallmentDirectDebit.vue'

@Component({
  name: 'StepPaymentMethod',
  components: {
    VRadio,
    PaymentMethodInvoice,
    PaymentMethodDirectDebit,
    PaymentMethodPrepayment,
    PaymentMethodInstallmentBankTransfer,
    PaymentMethodInstallmentDirectDebit,
  },
})
export default class StepPaymentMethod extends Vue {
  @Prop() private value!: PaymentMethodResponseType;
  @Prop() private customerData!: CustomerDataType;
  @Prop() private checkoutData!: FullCheckoutDataType;

  editData: PaymentDetailsType = { ...this.value.payment_details } as PaymentDetailsType
  customer: CustomerDataType = { ...this.customerData } as CustomerDataType
  // paymentOption: PaymentMethod = this.customer?.default_payment as PaymentMethod || this.methods[0]
  paymentOption: any = this.getDefaultPayment() || this.methods[0];
  moto: any = 'false'

  backgroundcolorData = ''

  beforeMount () {
    // console.log('beforeMount!!!!')
    // var test = this.methods
    // console.log('Test beforeMount: ' + test)
  }

  mounted (): void {
    // console.log('StepPAymentMethod Mounted customerData:' + JSON.stringify(this.customerData))
    // console.log('StepPAymentMethod Mounted value:' + JSON.stringify(this.value))
    console.log('StepPAymentMethod Mounted customer:' + JSON.stringify(this.customer))
    console.log('StepPAymentMethod Moto Global:' + this.moto)
  }

  getComponentName (option: string): string {
    var strippedOption = option.replace(/_[0-9]/g, '')
    const mapping: Record<string, string> = {
      'INVOICE_BANK_TRANSFER': 'PaymentMethodInvoice',
      'SEPA_DIRECT_DEBIT': 'PaymentMethodDirectDebit',
      'PREPAYMENT_BANK_TRANSFER': 'PaymentMethodPrepayment',
      'INSTALLMENT_BANK_TRANSFER': 'PaymentMethodInstallmentBankTransfer',
      'INSTALLMENT_DIRECT_DEBIT': 'PaymentMethodInstallmentDirectDebit',
    }

    return mapping[strippedOption]
  }

  getDefaultPayment (): any|null|undefined {
    /// -------
    var hasShipping = localStorage.getItem('hasShipping') ?? 'false'
    // var hasShipping = 'false'
    // console.log(hasShipping)
    var shipping = localStorage.getItem('shipping')
    var shippingparsed : any
    var billingparsed : any
    if (shipping) {
      shippingparsed = JSON.parse(shipping)
    }
    if (shippingparsed && shippingparsed.city === '') {
      shippingparsed = false
    }

    console.log('current shipping:')
    console.log(shippingparsed)

    var billing = localStorage.getItem('billing')
    if (billing === 'undefined' || billing === null) {
      billing = '{}'
    }
    console.log('current billing:')
    console.log(JSON.parse(billing))
    billingparsed = JSON.parse(billing)

    var methodsObj = this.value.allowed_methods
    console.log('allowed methods')
    console.log(this.value.allowed_methods)
    // console.log(methodsObj[prop])
    const allowed: any = []

    /*
    console.log('checkoutData:')
    console.log(JSON.stringify(this.checkoutData))
    console.log('customerData:')
    console.log(JSON.stringify(this.customerData))
    console.log('editData:')
    console.log(JSON.stringify(this.editData))
    console.log('value:')
    console.log(JSON.stringify(this.value))
    console.log('customer:')
    console.log(JSON.stringify(this.customer))
    console.log('methods:')
    console.log(Object.keys(methodsObj))
     */
    var methodNames: any = Object.keys(methodsObj)
    var b2b_allowed: any = 'b2b_allowed'
    var delivery_adress_allowed: any = 'delivery_address_allowed'
    var country_code_billing: any = 'country_code_billing'
    var country_code_delivery: any = 'country_code_delivery'
    var is_moto: any = 'is_moto'

    methodNames.forEach((methodName: any) => {
      console.log(methodName)
      console.log('is_moto' + methodsObj[methodName][is_moto].toString())
      console.log('moto Global' + localStorage.getItem('motoGlobal'))
      this.moto = localStorage.getItem('motoGlobal')
      console.log('MotoLog ende')
      var removeMethod = false
      if (methodsObj[methodName][b2b_allowed].toString() === 'false' && this.value.customer_type !== 'B2C') {
        removeMethod = true
        console.log('Removed ' + methodName + ' b2b not allowed and customer is B2B')
      }

      if (methodsObj[methodName][delivery_adress_allowed].toString() === 'false' && hasShipping.toString() === 'true') {
        removeMethod = true
        console.log('Removed ' + methodName + ' delivery addresses not allowed')
      }

      if (methodsObj[methodName][is_moto].toString() === 'false' && this.moto === 'true') {
        removeMethod = true
        console.log('Removed ' + methodName + ' because moto global is true and is_moto is false')
      }

      var bilcountries = methodsObj[methodName][country_code_billing].split(',')
      console.log('billcountries:')
      console.log(bilcountries)
      if (!bilcountries.includes(billingparsed.country_code.toString())) {
        removeMethod = true
        console.log('Removed ' + methodName + ' Billing Country ' + methodsObj[methodName][country_code_billing].toString() + ' is not allowed')
      }

      var delcountries = methodsObj[methodName][country_code_delivery].split(',')
      if (hasShipping === 'true' && !delcountries.includes(shippingparsed.country_code.toString())) {
        removeMethod = true
        console.log('Removed ' + methodName + ' Shipping Country ' + methodsObj[methodName][country_code_delivery].toString() + ' is not allowed')
      }

      if (!removeMethod) {
        console.log('allowing and pushin method:')
        console.log(methodName)
        allowed.push(methodName)
      }

      if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'true' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', true)
      } else if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'false' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', false)
      }
    })
    if (this.value.customer_type === 'B2B') {
      console.log('Type is B2B')
    } else {
      console.log('Type is B2C')
    }

    /// -------
    console.log('Option BEFORE getDefaultPayment: ' + this.paymentOption)
    console.log('Localstorage BEFORE getDefaultPayment: ' + localStorage.getItem('payment_method'))
    console.log('this.customerData.default_payment: ' + this.customerData.default_payment)
    console.log('this.value.payment_details?.payment_method' + this.value.payment_details?.payment_method)
    if (localStorage.getItem('payment_method') == null || typeof (localStorage.getItem('payment_method')) === 'undefined') {
      console.log('allowed is: ' + typeof (allowed))
      console.log('Test12345:' + allowed)
      if (this.customerData.default_payment === 'SEPA_DIRECT_DEBIT') {
        console.log('matched SEPA_DIRECT_DEBIT')
        localStorage.setItem('payment_method', PaymentMethod.SEPA_DIRECT_DEBIT)
        methodNames.every((methodName: any) => {
          console.log('FIND:' + allowed.findIndex((element: any) => element.includes(methodName)))
          if (methodName.search(PaymentMethod.SEPA_DIRECT_DEBIT.toString()) !== -1) {
            console.log('choosing' + methodName)
            this.paymentOption = methodName
            this.emit()

            return false
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'PREPAYMENT_BANK_TRANSFER') {
        console.log('matched PREPAYMENT_BANK_TRANSFER')
        localStorage.setItem('payment_method', PaymentMethod.PREPAYMENT_BANK_TRANSFER)
        methodNames.every((methodName: any) => {
          if (methodName.search(PaymentMethod.PREPAYMENT_BANK_TRANSFER.toString()) !== -1) {
            this.paymentOption = methodName
            this.emit()

            return false
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INSTALLMENT_BANK_TRANSFER') {
        console.log('matched INSTALLMENT_BANK_TRANSFER')
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_BANK_TRANSFER)
        methodNames.every((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_BANK_TRANSFER.toString()) !== -1) {
            this.paymentOption = methodName
            this.emit()

            return false
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INSTALLMENT_DIRECT_DEBIT') {
        console.log('matched INSTALLMENT_DIRECT_DEBIT')
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_DIRECT_DEBIT)
        methodNames.every((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_DIRECT_DEBIT.toString()) !== -1) {
            this.paymentOption = methodName
            this.emit()

            return false
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INVOICE_BANK_TRANSFER') {
        console.log('matched INVOICE_BANK_TRANSFER')
        localStorage.setItem('payment_method', PaymentMethod.INVOICE_BANK_TRANSFER)
        methodNames.every((methodName: any) => {
          if (methodName.search(PaymentMethod.INVOICE_BANK_TRANSFER.toString()) !== -1) {
            alert('Found Default Payment Method:' + methodName)
            console.log('this.methods:' + this.methods)
            this.paymentOption = methodName
            this.emit()

            return false
          }
        })

        return this.paymentOption
      }

      console.log('No default payment set using first allowed payment')

      return this.value.payment_details?.payment_method
    }

    return this.value.payment_details?.payment_method
  }

  getLabel (item : any, entry : any): any {
    var paymentMethod = item
    var methodsObj = this.value.allowed_methods
    var is_financing: any = 'is_financing'
    // console.log('entry:')
    // console.log(entry)
    if (typeof (methodsObj[entry][is_financing]) !== 'undefined' && methodsObj[entry][is_financing].toString() === 'true') {
      // console.log('Match:' + item)
      paymentMethod = paymentMethod.replace('Ratenzahlung', '0% Finanzierung')
      paymentMethod = paymentMethod.replace('Installments', '0% Financing')
      paymentMethod = paymentMethod.replace('Versement', '0% Financement')
    }
    // else {
    //  console.log("Test is false")
    // }
    // console.log('Label:')
    // console.log(paymentMethod)

    return paymentMethod
  }

  getProfile (item : any): any {
    var num = item.match(/\d/g).join('')
    // console.log('ProfileNum:')
    // console.log(num)

    return num
  }

  get methods (): any {
    console.log('Called get methods()!!!!;')
    console.log('localstorage shipping')
    var hasShipping = localStorage.getItem('hasShipping') ?? 'false'
    // var hasShipping = 'false'
    // console.log(hasShipping)
    var shipping = localStorage.getItem('shipping')
    var shippingparsed : any
    var billingparsed : any
    if (shipping) {
      shippingparsed = JSON.parse(shipping)
    }
    if (shippingparsed && shippingparsed.city === '') {
      shippingparsed = false
    }

    console.log('current shipping:')
    console.log(shippingparsed)

    var billing = localStorage.getItem('billing')
    if (billing === 'undefined' || billing === null) {
      billing = '{}'
    }
    console.log('current billing:')
    console.log(JSON.parse(billing))
    billingparsed = JSON.parse(billing)

    var methodsObj = this.value.allowed_methods
    console.log('allowed methods')
    console.log(this.value.allowed_methods)
    // console.log(methodsObj[prop])
    const allowed: any[] = []

    /*
    console.log('checkoutData:')
    console.log(JSON.stringify(this.checkoutData))
    console.log('customerData:')
    console.log(JSON.stringify(this.customerData))
    console.log('editData:')
    console.log(JSON.stringify(this.editData))
    console.log('value:')
    console.log(JSON.stringify(this.value))
    console.log('customer:')
    console.log(JSON.stringify(this.customer))
    console.log('methods:')
    console.log(Object.keys(methodsObj))
     */
    var methodNames: any = Object.keys(methodsObj)
    var b2b_allowed: any = 'b2b_allowed'
    var delivery_adress_allowed: any = 'delivery_address_allowed'
    var country_code_billing: any = 'country_code_billing'
    var country_code_delivery: any = 'country_code_delivery'
    var is_moto: any = 'is_moto'

    methodNames.forEach((methodName: any) => {
      console.log(methodName)
      console.log('is_moto' + methodsObj[methodName][is_moto].toString())
      console.log('moto Global' + localStorage.getItem('motoGlobal'))
      this.moto = localStorage.getItem('motoGlobal')
      console.log('MotoLog ende')
      var removeMethod = false
      if (methodsObj[methodName][b2b_allowed].toString() === 'false' && this.value.customer_type !== 'B2C') {
        removeMethod = true
        console.log('Removed ' + methodName + ' b2b not allowed and customer is B2B')
      }

      if (methodsObj[methodName][delivery_adress_allowed].toString() === 'false' && hasShipping.toString() === 'true') {
        removeMethod = true
        console.log('Removed ' + methodName + ' delivery addresses not allowed')
      }

      if (methodsObj[methodName][is_moto].toString() === 'false' && this.moto === 'true') {
        removeMethod = true
        console.log('Removed ' + methodName + ' because moto global is true and is_moto is false')
      }

      var bilcountries = methodsObj[methodName][country_code_billing].split(',')
      console.log('billcountries:')
      console.log(bilcountries)
      if (!bilcountries.includes(billingparsed.country_code.toString())) {
        removeMethod = true
        console.log('Removed ' + methodName + ' Billing Country ' + methodsObj[methodName][country_code_billing].toString() + ' is not allowed')
      }

      var delcountries = methodsObj[methodName][country_code_delivery].split(',')
      if (hasShipping === 'true' && !delcountries.includes(shippingparsed.country_code.toString())) {
        removeMethod = true
        console.log('Removed ' + methodName + ' Shipping Country ' + methodsObj[methodName][country_code_delivery].toString() + ' is not allowed')
      }

      if (!removeMethod) {
        console.log('allowing and pushin method:')
        console.log(methodName)
        allowed.push(methodName)
      }

      if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'true' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', true)
      } else if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'false' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', false)
      }
    })
    if (this.value.customer_type === 'B2B') {
      console.log('Type is B2B')
    } else {
      console.log('Type is B2C')
    }

    return allowed // return orderedMethods.filter(entry => (methods || []).includes(entry))
  }

  get paymentOptionModel (): PaymentMethod {
    console.log('getPaymentOptionModel:' + this.paymentOption)

    return this.paymentOption
  }

  set paymentOptionModel (option: PaymentMethod) {
    console.log('setPaymentOptionModel:' + option)
    this.paymentOption = option
    this.emit()
  }

  get editDataModel (): PaymentDetailsType {
    return this.editData
  }

  set editDataModel (data: PaymentDetailsType) {
    this.editData = data
    this.emit()
  }

  emit (): void {
    this.$emit('input', {
      ...this.value,
      payment_details: {
        ...this.editData,
        payment_method: this.paymentOption,
      },
    } as PaymentMethodResponseType)
  }
}
