export enum Gender {
  UNKNOWN = 'U',
  FEMALE = 'F',
  MALE = 'M',
}

export enum Type {
  B2B = 'B2B',
  B2C = 'B2C',
}
