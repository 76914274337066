var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "buyer-data" }, [
    _vm.customerData
      ? _c(
          "div",
          { staticClass: "buyer buyer-entry" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("buyer_your_data_title"))),
            ]),
            _c("BuyerDataEntry", {
              attrs: { customer: _vm.customerData },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return [
                        data
                          ? _c("div", { staticClass: "data-line" }, [
                              _vm._v(_vm._s(data)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                67824935
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.address
      ? _c(
          "div",
          { staticClass: "address buyer-entry" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("buyer_address_title"))),
            ]),
            _c("Address", {
              attrs: { isShipping: false, address: _vm.address },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return [
                        data
                          ? _c("div", { staticClass: "data-line" }, [
                              _vm._v(_vm._s(data)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                67824935
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.shippingAddress
      ? _c(
          "div",
          { staticClass: "shipping-address buyer-entry" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("buyer_shipping_address_title"))),
            ]),
            _c("Address", {
              attrs: { isShipping: true, address: _vm.shippingAddress },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return [
                        data
                          ? _c("div", { staticClass: "data-line" }, [
                              _vm._v(_vm._s(data)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                67824935
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }